import {
  Check,
  CopySimple,
  Envelope,
  PencilSimple,
  Phone,
  TagChevron,
  X,
} from "@phosphor-icons/react";
import React, { useEffect, useReducer, useState } from "react";
import { ManageUserTags } from "../../components/Tag";
import { useDispatch, useSelector } from "react-redux";
import {
  FetchCurrentUser,
  UpdateConversationTags,
  UpdateIPTVCredentials,
  UpdateNote,
  UpdatePlanDuration,
} from "../../redux/slices/chat";
import { toast } from "react-toastify";
import EditAttributes from "../../components/EditAttributes";

export default function ({ handleToggle }) {
  const dispatch = useDispatch();
  // const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const { conversations, currentConversation, currentUser, isLoading } =
    useSelector((state) => state.chat);

    const {apps} = useSelector((state) => state.application);
    const {devices} = useSelector((state) => state.device);
    const {servers} = useSelector((state) => state.server);

  const [openManageTags, setOpenManageTags] = useState(false);
  const [editIPTVCredentials, setEditIPTVCredentials] = useState(false);
  const [editAttributes, setEditAttributes] = useState(false);
  const [editNote, setEditNote] = useState(false);
  const [editPlan, setEditPlan] = useState(false);

  const [thisConversation, setThisConversation] = useState({});
  const [iptvUsername, setIPTVUsername] = useState("");
  const [iptvPassword, setIPTVPassword] = useState("");
  const [note, setNote] = useState("");
  const [planDuration, setPlanDuration] = useState(0);

  const handleToggleEditAttributes = () => {
    setEditAttributes((p) => !p);
  };

  // Fetch current user when conversation changes
  useEffect(() => {
    if (currentConversation) {
      dispatch(FetchCurrentUser(currentConversation));
    }
  }, [currentConversation]);

  // Update conversation and dependent state
  useEffect(() => {
    const conversation = conversations?.find(
      (el) => el._id === currentConversation
    );
    setThisConversation(conversation || {});
  }, [currentConversation, conversations]);

  // Update user-dependent state
  useEffect(() => {
    setIPTVUsername(currentUser?.iptv_username || "");
    setIPTVPassword(currentUser?.iptv_password || "");
    setNote(currentUser?.user_note || "");
    setPlanDuration(currentUser?.planDuration || 0);
  }, [currentUser]);

  const handleToggleManageTags = () => setOpenManageTags((prev) => !prev);

  const handleCancelEditNote = () => {
    setNote(currentUser?.user_note || "");
    setEditNote(false);
  };

  const handleCancelEditPlan = () => {
    setPlanDuration(currentUser?.planDuration || 0);
    setEditPlan(false);
  };

  const handleCancelEditIPTVCredentials = () => {
    setIPTVUsername(currentUser?.iptv_username || "");
    setIPTVPassword(currentUser?.iptv_password || "");
    setEditIPTVCredentials(false);
  };

  const handleSubmitPlan = () => {
    const data = { participant_id: currentUser?._id, planDuration };

    dispatch(
      UpdatePlanDuration(data, () => {
        setEditPlan(false);
      })
    );
  };

  const handleSubmitNote = () => {
    const data = { participant_id: currentUser?._id, note };
    dispatch(
      UpdateNote(data, () => {
        setEditNote(false);
        // forceUpdate();
      })
    );
  };

  const handleSubmitIPTVCredentials = () => {
    const data = {
      participant_id: currentUser?._id,
      iptvUsername,
      iptvPassword,
    };
    dispatch(
      UpdateIPTVCredentials(data, () => {
        setEditIPTVCredentials(false);
        // forceUpdate();
      })
    );
  };

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => toast.success("Copied to clipboard!"))
      .catch(() => toast.error("Failed to copy!"));
  };

  const LIST = thisConversation?.tags?.map((tag) => ({
    key: tag._id,
    title: tag.name,
    color: tag.colorCode,
  }));

  const appName = apps?.find((el) => el?._id === thisConversation?.application)?.name;
  const deviceName = devices?.find((el) => el?._id === thisConversation?.device)?.name;
  const serverName = servers?.find((el) => el?._id === thisConversation?.iptvServer)?.name;

  const name = currentUser?.name || currentUser?.username;

  return (
    <>
      <div className="border-l flex flex-col h-full border-stroke dark:border-strokedark">
        <div className="sticky border-b border-stroke px-6 py-6 dark:border-strokedark flex flex-row items-center justify-between w-full">
          <div className="text-black dark:text-white font-semibold text-lg">
            Profile
          </div>

          <button className="" onClick={handleToggle}>
            <X size={24} />
          </button>
        </div>

        {/* Scrollable container */}
        <div className="overflow-y-auto no-scrollbar max-h-full flex-grow">
          <div className="px-6 space-y-1 mb-3 mt-6">
            <div className="text-black dark:text-white text-xl font-medium">
              {name}
            </div>
          </div>
          {/*  */}

          {/* -------------- IPTV Credentials start -------------- */}
          <div className="px-6 mb-3">
            <div className="px-1 pt-3 dark:border-strokedark space-y-4">
              <div className="flex flex-row items-center justify-between">
                <div className="font-semibold">IPTV Credentials</div>

                {editIPTVCredentials ? (
                  <div className="flex flex-row items-center space-x-4">
                    <button
                      disabled={isLoading}
                      onClick={(e) => {
                        // handleSubmitIPTVCredentials
                        e.preventDefault();
                        handleSubmitIPTVCredentials();
                      }}
                    >
                      {isLoading ? (
                        <>
                          <svg
                            className="mr-2 inline h-4 w-4 animate-spin text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C3.58 0 0 3.58 0 8h4z"
                            ></path>
                          </svg>
                        </>
                      ) : (
                        <Check size={24} />
                      )}
                    </button>
                    <button
                      onClick={() => {
                        handleCancelEditIPTVCredentials();
                      }}
                    >
                      <X size={24} />
                    </button>
                  </div>
                ) : (
                  <button
                    onClick={() => {
                      setEditIPTVCredentials(true);
                    }}
                  >
                    <PencilSimple size={24} />
                  </button>
                )}
              </div>
              <div className="space-y-3">
                <div className="flex flex-row items-center justify-between space-x-3">
                  <div className="flex flex-row items-center space-x-2.5">
                    <span className="text-sm">Username</span>
                    {editIPTVCredentials ? (
                      <input
                        type="text"
                        value={iptvUsername}
                        onChange={(e) => {
                          setIPTVUsername(e.target.value);
                        }}
                        className="w-full rounded-lg border border-stroke bg-transparent py-2 pl-6 pr-6 text-black outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                      />
                    ) : (
                      <div className="text-sm">
                        {iptvUsername || "Username not found"}
                        {/* {currentUser?.phone || "Phone not found"} */}
                      </div>
                    )}
                  </div>

                  <button
                    disabled={!currentUser?.iptv_username}
                    onClick={() => {
                      copyToClipboard(currentUser?.iptv_username);
                    }}
                  >
                    <CopySimple size={20} />
                  </button>
                </div>

                <div className="flex flex-row items-center justify-between space-x-3">
                  <div className="flex flex-row items-center space-x-3">
                    <span className="text-sm">Password</span>
                    {editIPTVCredentials ? (
                      <input
                        type="text"
                        value={iptvPassword}
                        onChange={(e) => {
                          setIPTVPassword(e.target.value);
                        }}
                        className="w-full rounded-lg border border-stroke bg-transparent py-2 pl-6 pr-6 text-black outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                      />
                    ) : (
                      <div className="text-sm">
                        {iptvPassword || "Password not found"}
                      </div>
                    )}
                  </div>

                  <button
                    disabled={!iptvPassword}
                    onClick={() => {
                      copyToClipboard(iptvPassword);
                    }}
                  >
                    <CopySimple size={20} />
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* -------------- IPTV Credentials end -------------- */}

          {/* Contact Details : NOT Shown to Employees */}
          {false && (
            <div className="px-6 mb-3">
              <div className="border-b border-t px-1 py-3 border-stroke dark:border-strokedark space-y-3">
                <div className="font-semibold">Contact Details</div>
                <div className="space-y-3">
                  <div className="flex flex-row items-center space-x-3">
                    <Phone size={20} />
                    <div className="text-sm">
                      ******
                    </div>
                  </div>
                  <div className="flex flex-row items-center space-x-3">
                    <Envelope size={20} />
                    <div className="text-sm">
                      {"Email not found"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Tags */}
          <div className="px-6 mb-3">
            <div className="border-b border-t px-1 py-3 border-stroke dark:border-strokedark space-y-3">
              <div className="font-semibold">Tags</div>
              <div className="flex flex-row flex-wrap gap-2">
                {LIST?.length === 0 ? (
                  <div className="text-lg font-semibold text-center">
                    No Tags Found
                  </div>
                ) : (
                  LIST?.map(({ key, title, color }) => (
                    <div
                      key={key}
                      className="border border-stroke dark:border-strokedark px-4 py-1.5 rounded-full flex flex-row items-center"
                    >
                      <TagChevron
                        weight="fill"
                        className="mr-2"
                        color={color}
                      />
                      {title}
                      <button
                        onClick={() => {
                          const data = {
                            conversationId: currentConversation,
                            include: [],
                            exclude: [key],
                          };
                          dispatch(UpdateConversationTags(data, () => {}));
                        }}
                        className="ml-2"
                      >
                        <X />
                      </button>
                    </div>
                  ))
                )}
              </div>
              <button
                onClick={handleToggleManageTags}
                className="bg-transparent border text-primary border-primary w-full text-center py-3 rounded-lg"
              >
                Add New Tag
              </button>
            </div>
          </div>

          {/* Plan Duration */}
          <div className="px-6 mb-3">
            <div className="border-b px-1 py-3 border-stroke dark:border-strokedark space-y-3">
              <div className="flex flex-row items-center justify-between">
                <div className="font-semibold">Subscription Plan</div>

                {editPlan ? (
                  <div className="flex flex-row items-center space-x-4">
                    <button
                      disabled={isLoading}
                      onClick={(e) => {
                        e.preventDefault();
                        handleSubmitPlan();
                      }}
                    >
                      {isLoading ? (
                        <>
                          <svg
                            className="mr-2 inline h-4 w-4 animate-spin text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C3.58 0 0 3.58 0 8h4z"
                            ></path>
                          </svg>
                        </>
                      ) : (
                        <Check size={24} />
                      )}
                    </button>
                    <button
                      onClick={() => {
                        handleCancelEditPlan();
                      }}
                    >
                      <X size={24} />
                    </button>
                  </div>
                ) : (
                  <button
                    onClick={() => {
                      setEditPlan(true);
                    }}
                  >
                    <PencilSimple size={24} />
                  </button>
                )}
              </div>

              {!editPlan ? (
                <span className="text-lg text-primary py-3">
                  {planDuration} Days Remaining
                </span>
              ) : (
                <input
                  value={planDuration}
                  type="number"
                  min={0}
                  max={365}
                  onChange={(e) => {
                    setPlanDuration(e.target.value);
                  }}
                  className="w-full rounded-lg border border-stroke bg-transparent py-2 pl-6 pr-6 text-black outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                />
              )}
            </div>
          </div>

          {/* Application, Device, Server - Attributes */}
          <div className="px-6 mb-3">
            <div className="px-1 pt-3 dark:border-strokedark border-b pb-6 space-y-4">
              <div className="flex flex-row items-center justify-between">
                <div className="font-semibold">Attributes</div>

                <button onClick={handleToggleEditAttributes}>
                  <PencilSimple size={24} />
                </button>
              </div>
              <div className="space-y-3">
                {/* Application */}
                <div className="flex flex-row items-center justify-between space-x-3">
                  <div className="flex flex-row items-center space-x-2.5">
                    <span className="text-sm">Application</span>
                  </div>
                  <div className="text-sm">
                    {/* // ? CHANGE HERE */}
                    {appName || "Application not found"}
                  </div>
                </div>
                {/* Device */}
                <div className="flex flex-row items-center justify-between space-x-3">
                  <div className="flex flex-row items-center space-x-2.5">
                    <span className="text-sm">Device</span>
                  </div>
                  <div className="text-sm">
                    {/* // ? CHANGE HERE */}
                    {deviceName || "Device not found"}
                  </div>
                </div>
                {/* Server */}
                <div className="flex flex-row items-center justify-between space-x-3">
                  <div className="flex flex-row items-center space-x-2.5">
                    <span className="text-sm">IPTV Server</span>
                  </div>
                  <div className="text-sm">
                    {/* // ? CHANGE HERE */}
                    {serverName || "IPTV Server not found"}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Note */}
          <div className="px-6 mb-3">
            <div className="px-1 dark:border-strokedark space-y-4">
              <div className="flex flex-row items-center justify-between">
                <div className="font-semibold">Note</div>

                {editNote ? (
                  <div className="flex flex-row items-center space-x-4">
                    <button
                      disabled={isLoading}
                      onClick={(e) => {
                        // handleSubmitIPTVCredentials
                        e.preventDefault();
                        handleSubmitNote();
                      }}
                    >
                      {isLoading ? (
                        <>
                          <svg
                            className="mr-2 inline h-4 w-4 animate-spin text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C3.58 0 0 3.58 0 8h4z"
                            ></path>
                          </svg>
                        </>
                      ) : (
                        <Check size={24} />
                      )}
                    </button>
                    <button
                      onClick={() => {
                        handleCancelEditNote();
                      }}
                    >
                      <X size={24} />
                    </button>
                  </div>
                ) : (
                  <button
                    onClick={() => {
                      setEditNote(true);
                    }}
                  >
                    <PencilSimple size={24} />
                  </button>
                )}
              </div>

              {!editNote ? (
                <div
                  onClick={() => {
                    setEditNote(true);
                  }}
                  className="p-3 bg-yellow-100 rounded-md min-h-10 text-black max-w-full break-words"
                >
                  <span>{note}</span>
                </div>
              ) : (
                <textarea
                  rows={5}
                  value={note}
                  readOnly={!editNote}
                  onChange={(e) => {
                    setNote(e.target.value);
                  }}
                  className="w-full rounded-lg border border-stroke bg-transparent py-2 pl-6 pr-6 text-black outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {editAttributes && (
        <EditAttributes
          open={editAttributes}
          handleClose={handleToggleEditAttributes}
        />
      )}

      {openManageTags && (
        <ManageUserTags
          open={openManageTags}
          handleClose={handleToggleManageTags}
        />
      )}
    </>
  );
}
