import { Check, Checks, DownloadSimple, File } from "@phosphor-icons/react";
import React, { useState } from "react";
import MediaMsgGrid from "../MediaMsgGrid";
import MessagePopover from "../Popover/MessagePopover";
import Deleted from "./Deleted";

export default function Media({
  edited,
  disableAction,
  id,
  incoming,
  author,
  timestamp,
  media,
  caption,
  active,
  sender,
  agent_name,
}) {
  const [hovered, setHovered] = useState(false);

  return active ? incoming ? (
    <div className={`max-w-sm`}>
      <p className="mb-2.5 text-sm font-medium capitalize">{author}</p>
      <div
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(null)}
        className="relative mb-2.5 rounded-2xl rounded-tl-none bg-gray text-white px-5 py-3 dark:bg-boxdark-2 space-y-2"
      >
        <p className="mb-2.5 text-sm font-medium">{sender?.type}</p>
        <MediaMsgGrid incoming={incoming} media={media} />

        <p>{caption}</p>
        {!disableAction && hovered && (
          <div className="absolute top-2 right-2">
            {/* <MessagePopover id={id} incoming={incoming} /> */}
          </div>
        )}
      </div>
      <div className="flex flex-row items-center justify-end space-x-2">
        {edited && <p className="text-xs">Edited</p>}
        <p className="text-xs">{timestamp}</p>
      </div>
    </div>
  ) : (
    <div className="ml-auto max-w-sm">
      <div
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(null)}
        className="relative mb-2.5 rounded-2xl rounded-br-none bg-primary px-5 py-3 space-y-2"
      >
        <p className="mb-2.5 text-sm font-medium">{sender?.type} ({agent_name})</p>
        <MediaMsgGrid incoming={incoming} media={media} />
        <p className="text-white">{caption}</p>
        {!disableAction && hovered && (
          <div className="absolute top-2 right-2">
            {/* <MessagePopover id={id} incoming={incoming} /> */}
          </div>
        )}
      </div>
      <div className="flex flex-row items-center justify-end space-x-2">
        {edited && <p className="text-xs">Edited</p>}
        <p className="text-xs">{timestamp}</p>
      </div>
    </div>
  ) : <Deleted incoming={incoming} id={id} /> ;
}
