import React, { useEffect } from "react";
import Input from "./Input";
import Header from "./Header";
import Messages from "./Messages";
import UserInfo from "./UserInfo";
import { useDispatch, useSelector } from "react-redux";
import { ChatCircleDots } from "@phosphor-icons/react";
import { connectWithSocketServer } from "../../socket/socketConnection";
import {
  AddMessage,
  DeleteMessageContent,
  FetchChatHistory,
  UpdateMessageContent,
  MarkConversationAsRead,
  UpdateUserInfo,
  UpdateSendingMsg,
  UpdateUnassignedTotal,
  TicketTaken,
  TicketAvailable,
  AssignTicketToMe,
} from "../../redux/slices/chat";
import { toast } from "react-toastify";

import Chime from "../../assets/audio/new-message-chime.wav";
import OutgoingChime from "../../assets/audio/outgoing-message-chime.wav";

export default function Inbox() {
  const dispatch = useDispatch();

  const { isLoggedIn, token, user_id } = useSelector((state) => state.auth);

  const currentConversation = window.localStorage.getItem(
    "current-conversation"
  );

  console.log(
    currentConversation?.toString(),
    "current-conversation from local storage"
  );
  // alert(currentConversation);

  const { userInfoOpen } = useSelector((state) => state.chat);

  const handleToggleUserInfo = () => {
    dispatch(UpdateUserInfo(!userInfoOpen));
  };

  useEffect(() => {
    let socket;
    if (isLoggedIn) {
      socket = connectWithSocketServer({
        token: token,
      });

      // ** WORKING
      socket.on("connect", () => {
        console.log("succesfully connected with socket.io server");
        console.log(socket.id);
      });

      // ** WORKING
      socket.on("error", (data) => {
        // ? DATA FORMAT

        // data = { message: "Conversation not found" }

        console.error("error occured:", data);

        if (data.reason === "ASSIGNED_ALREADY") {
          toast.info("This ticket is answered by someone else!");
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }

        // console.log(data);
        dispatch(UpdateSendingMsg(false));
        // toast.error(data?.message || "Something went wrong");
      });

      socket.on("ticket-available", (data) => {
        console.log(data, "ticket-available");

        // ? DATA FORMAT

        // const res_data = {
        //   conversation
        // };

        dispatch(TicketAvailable(data.conversation));
      });

      socket.on("ticket-assigned", (data) => {
        console.log(data, "ticket-assigned");

        // ? DATA FORMAT

        // const res_data = {
        //   id: conversation._id
        // };

        dispatch(
          AssignTicketToMe({
            conversation_id: data.id?.toString(),
            agent_id: user_id,
          })
        );

        toast.info("Ticket assigned to you!");
      });

      socket.on("ticket-taken", (data) => {
        console.log(data, "ticket-taken");

        // ? DATA FORMAT

        // const res_data = {
        //   id: conversation._id
        // };

        const currentConversation = window.localStorage.getItem(
          "current-conversation"
        );

        if (currentConversation.toString() === data.id.toString()) {
          toast.info("This ticket is answered by someone else!");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          // remove the conversation from list & also reduce number of unassigned ticket by 1
          dispatch(TicketTaken(data.id.toString()));
        }
      });

      socket.on("total-unassigned", (data) => {
        console.log(data, "total-unassigned");

        // ? DATA FORMAT

        // const res_data = {
        //   totalUnassigned
        // };

        // ** DONE: UpdateUnassignedTotal
        dispatch(UpdateUnassignedTotal(data));
      });

      // ** WORKING
      socket.on("chat-history", (data) => {
        console.log(data, "chat-history");

        // ? DATA FORMAT

        // const res_data = {
        //   participant,
        //   history: conversation.messages,
        // };

        // ** DONE: Create FetchChatHistory
        dispatch(FetchChatHistory(data));
      });

      // ** WORKING
      socket.on("new-direct-chat-incoming", (data) => {
        console.log(data, "new-direct-chat-incoming");

        // ? DATA FORMAT

        // data = {
        //   participant,
        //   message: newMessage,
        //   conversation,
        // }

        console.log(data.conversation, "new direct chat incoming");

        const assignment = data.conversation?.assignment;

        // ** DONE: Check if conversation is assigned to bot or some other employee - if yes - don't process - otherwise - process

        const isAssignedToSomeoneElse = assignment
          ? assignment?.type === "Bot"
            ? true
            : assignment?.type === "Agent" &&
              assignment?.agent?.toString() !== user_id?.toString()
            ? true
            : false
          : false;

        if (!isAssignedToSomeoneElse) {
          // ** DONE: Create AddNewMessage
          dispatch(AddMessage(data));

          const notificationMsg = `New message from ${data?.conversation?.participant_name}`;

          // show toast & play notification sound
          toast.info(notificationMsg);

          const chime = new Audio(Chime); // Path to the sound file
          chime
            .play()
            .catch((error) => console.log("Audio play error:", error));
        }
      });

      // ** WORKING
      socket.on("new-direct-chat-outgoing", (data) => {
        try {
          console.log(data, "new-direct-chat-outgoing");

          // ? DATA FORMAT

          // data = {
          //   participant,
          //   message: newMessage,
          //   conversation,
          // }

          const assignment = data.conversation?.assignment;

          const isAssignedToSomeoneElse = assignment
            ? assignment?.type === "Bot"
              ? true
              : assignment?.type === "Agent" &&
                assignment?.agent?.toString() !== user_id?.toString()
              ? true
              : false
            : false;

          // ** DONE: Create AddNewMessage
          dispatch(AddMessage(data));

          if (!isAssignedToSomeoneElse) {
            // if (currentConversation?.toString() === data.conversation._id.toString()) => play OutgoingChime
            console.log(
              currentConversation,
              data.conversation,
              "testing data in outgoing msg"
            );
            if (
              currentConversation?.toString() ===
              data.conversation._id?.toString()
            ) {
              // Play outgoing notification sound

              const chime = new Audio(OutgoingChime); // Path to the sound file
              chime
                .play()
                .catch((error) => console.log("Audio play error:", error));
            }
          }
        } catch (error) {
          console.log(error, "outgoing chat error");
        }
      });

      socket.on("message-deleted", (data) => {
        console.log(data, "message-deleted");

        // ? DATA FORMAT

        // data = {
        //  participant,
        //   messageId,
        // }

        // ** DONE: Create Delete Message
        dispatch(DeleteMessageContent(data));
      });

      socket.on("message-updated", (data) => {
        console.log(data, "message-updated");

        // ? DATA FORMAT

        // data = {
        //  participant,
        //  messageId,
        //  content,
        // }

        // ** DONE: Create Update Message
        dispatch(UpdateMessageContent(data));
      });

      socket.on("mark-read", (data) => {
        console.log(data, "mark-read");

        // ? DATA FORMAT

        // data = {
        //   id
        // }

        // ** DONE: Mark conversation as read
        dispatch(MarkConversationAsRead(data.id));
      });
    }

    // Cleanup when the component unmounts or when the socket is manually disconnected
    return () => {
      if (socket) {
        socket.off("connect");
        socket.off("error");
        // socket.off("user-disconnected");
        // socket.off("user-connected");
        socket.off("chat-history");
        socket.off("new-direct-chat");
        socket.off("message-deleted");
        socket.off("message-updated");

        // socket.off("start-typing");
        // socket.off("stop-typing");

        // ? DISCONNECT SOCKET
        socket.disconnect();
        console.log("Disconnected from socket server");
      }
    };
  }, []);

  return (
    <>
      {currentConversation?.toString() !== "null" ? (
        <div
          className={`flex h-full flex-col border-l border-stroke dark:border-strokedark ${
            userInfoOpen ? "xl:w-1/2" : "xl:w-3/4"
          }`}
        >
          {/* <!-- ====== Chat Box Start --> */}
          <Header handleToggleUserInfo={handleToggleUserInfo} />

          {/* Messages */}
          <Messages />

          {/* Chat INPUT */}
          <Input />
          {/* <!-- ====== Chat Box End --> */}
        </div>
      ) : (
        <div
          className={`flex h-full flex-col border-l border-stroke dark:border-strokedark xl:w-3/4 items-center justify-center space-y-4`}
        >
          <ChatCircleDots size={48} />
          <div className="text-xl font-semibold">
            Please select a conversation
          </div>
        </div>
      )}

      {userInfoOpen && (
        <div className="w-1/4">
          <UserInfo handleToggle={handleToggleUserInfo} />
        </div>
      )}
    </>
  );
}
