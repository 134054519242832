import io from "socket.io-client";
import { BASE_URL } from "../utils/axios";
import { store } from "../redux/store";
import { UpdateSendingMsg } from "../redux/slices/chat";

let socket = null;

export const connectWithSocketServer = (userDetails) => {
  console.log("connecting with socket server");

  const jwtToken = userDetails.token;

  socket = io(BASE_URL, {
    auth: {
      token: jwtToken,
    },
  });

  return socket;
};

export const sendDirectMessage = (data) => {
  // ? DATA FORMAT

  // const { message, conversationId } = data;
  // where message is 👇
  // const { author, content, media, audioUrl, documentUrl, type, giphyUrl } = message;

  console.log(data, 'debugging');

  store.dispatch(UpdateSendingMsg(true));

  socket.emit("new-message", data);
};

export const getDirectChatHistory = (data) => {
  // ? DATA FORMAT

  console.log(data, "direct-chat-history");

  // const { participant } = data;

  socket.emit("direct-chat-history", data);
};

export const deleteMessageById = (data) => {
  // ? DATA FORMAT

  console.log(data, "delete-message");

  // const {participant, messageId} = data;

  socket.emit("delete-message", data);
};

export const editMessageById = (data) => {
  // ? DATA FORMAT

  console.log(data, "edit-message");

  // const {participant, messageId, content} = data;

  socket.emit("edit-message", data);
};

export const markConversationReadById = (id) => {
  // ? DATA FORMAT

  console.log({ id }, "mark-read");

  // const {id} = data;

  socket.emit("mark-read", { id });
};

export default socket;
