import { Empty, Plus } from "@phosphor-icons/react";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import { AddDevice, DeleteDevice, EditDevice } from "../../components/device";
import { FetchDevices } from "../../redux/slices/device";

const DevicesTable = () => {
  const [id, setId] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const { devices } = useSelector((state) => state.device);

  const LIST = devices?.map((device) => ({
    id: device?._id,
    name: device?.name,
    users: device?.conversations.length,
    createdAt: device?.createdAt,
    createdBy:
      device?.createdBy?.role === "Admin" ? "Admin" : device?.createdBy?.name,
  }));

  const handleToggleEdit = () => {
    setOpenEdit((p) => !p);
  };

  const handleToggleDelete = () => {
    setOpenDelete((p) => !p);
  };

  return (
    <>
      <div className="overflow-hidden rounded-[10px]">
        <div className="max-w-full overflow-y-auto overflow-x-hidden">
          <div className="min-w-[1170px]">
            {/* table header start */}
            <div className="grid grid-cols-12 bg-[#F9FAFB] px-5 py-4 dark:bg-meta-4 lg:px-7.5 2xl:px-11">
              <div className="col-span-3">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">
                  Device NAME
                </h5>
              </div>

              <div className="col-span-2">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">
                  USERS
                </h5>
              </div>

              <div className="col-span-3">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">
                  CREATED BY
                </h5>
              </div>

              <div className="col-span-1">
                <h5 className="font-medium text-[#637381] dark:text-bodydark">
                  CREATED AT
                </h5>
              </div>
            </div>
            {/* table header end */}

            {/* table body start */}
            <div className="bg-white dark:bg-boxdark">
              {LIST?.length > 0 ? (
                LIST?.map((device, index) => (
                  <div
                    key={index}
                    className="grid grid-cols-12 border-t border-[#EEEEEE] px-5 py-4 dark:border-strokedark lg:px-7.5 2xl:px-11"
                  >
                    <div className="col-span-3 flex flex-row items-center space-x-2">
                      <p className="text-[#637381] dark:text-bodydark">
                        {device.name}
                      </p>
                    </div>

                    <div className="col-span-2">
                      <p className="text-[#637381] dark:text-bodydark">
                        {device.users}
                      </p>
                    </div>

                    <div className="col-span-3">
                      <p className="text-[#637381] dark:text-bodydark">
                        {device.createdBy}
                      </p>
                    </div>

                    <div className="col-span-2">
                      <p className="text-[#637381] dark:text-bodydark">
                        {new Date(device.createdAt).toLocaleDateString()}
                      </p>
                    </div>

                    <div className="col-span-2 space-x-4">
                      <button
                        onClick={() => {
                          setId(device.id);
                          handleToggleEdit();
                        }}
                        className="text-primary"
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => {
                          setId(device.id);
                          handleToggleDelete();
                        }}
                        className="text-danger"
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <div className="flex flex-col items-center justify-center py-24">
                  <div className="text-xl font-semibold">No devices found!</div>
                </div>
              )}
            </div>
            {/* table body end */}
          </div>
        </div>
      </div>

      {openDelete && (
        <DeleteDevice
          id={id}
          open={openDelete}
          handleClose={handleToggleDelete}
        />
      )}
      {openEdit && (
        <EditDevice
          id={id}
          open={openEdit}
          handleClose={handleToggleEdit}
        />
      )}
    </>
  );
};

const DevicesPage = () => {
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();

  const handleToggleAddDevice = () => {
    setOpen((p) => !p);
  };

  // Debounced version of the dispatch
  const debouncedFetchDevices = useCallback(
    debounce((query) => {
      dispatch(FetchDevices(query));
    }, 500),
    [dispatch]
  );

  // Update the debounced fetch whenever the searchTerm changes
  useEffect(() => {
    debouncedFetchDevices(searchTerm);
    // Cleanup the debounce on component unmount
    return () => {
      debouncedFetchDevices.cancel();
    };
  }, [searchTerm, debouncedFetchDevices]);

  // Fetch all devices on initial render
  useEffect(() => {
    dispatch(FetchDevices());
  }, [dispatch]);

  return (
    <>
      <div className="flex w-full flex-col space-y-8">
        <div className="flex flex-row items-center justify-between">
          <form className="relative">
            <input
              type="text"
              value={searchTerm} // Controlled input
              onChange={(e) => setSearchTerm(e.target.value)} // Update search term
              className="w-full rounded border border-stroke bg-gray-2 py-2.5 pl-5 pr-10 text-sm outline-none focus:border-primary dark:border-strokedark dark:bg-boxdark-2"
              placeholder="Search..."
            />
            <button
              type="button"
              className="absolute right-4 top-1/2 -translate-y-1/2"
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.25 3C5.3505 3 3 5.3505 3 8.25C3 11.1495 5.3505 13.5 8.25 13.5C11.1495 13.5 13.5 11.1495 13.5 8.25C13.5 5.3505 11.1495 3 8.25 3ZM1.5 8.25C1.5 4.52208 4.52208 1.5 8.25 1.5C11.9779 1.5 15 4.52208 15 8.25C15 11.9779 11.9779 15 8.25 15C4.52208 15 1.5 11.9779 1.5 8.25Z"
                  fill="#637381"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11.957 11.958C12.2499 11.6651 12.7247 11.6651 13.0176 11.958L16.2801 15.2205C16.573 15.5133 16.573 15.9882 16.2801 16.2811C15.9872 16.574 15.5124 16.574 15.2195 16.2811L11.957 13.0186C11.6641 12.7257 11.6641 12.2508 11.957 11.958Z"
                  fill="#637381"
                />
              </svg>
            </button>
          </form>

          <button
            onClick={handleToggleAddDevice}
            className="flex flex-row items-center justify-center space-x-3 rounded-lg bg-primary px-4 py-2 text-white hover:cursor-pointer hover:bg-opacity-90"
          >
            <Plus size={20} />
            <span>Add New Device</span>
          </button>
        </div>
        <DevicesTable />
      </div>

      {open && <AddDevice open={open} handleClose={handleToggleAddDevice} />}
    </>
  );
};

export default DevicesPage;
