import React, { useEffect, useState } from "react";
import {
  Empty,
  File,
  FunnelSimple,
  Gif,
  HandPalm,
  Image,
  Microphone,
  Robot,
  TagChevron,
  UserCircleCheck,
  X,
} from "@phosphor-icons/react";
import { TagFilter } from "../../components/Filter";
import ChatPopover from "../../components/Popover/ChatPopover";
import { useDispatch, useSelector } from "react-redux";
import {
  FetchCount,
  GetConversations,
  SetCurrentConversation,
  UpdateFilterCategory,
} from "../../redux/slices/chat";
import truncateString from "../../utils/truncate";
import { markConversationReadById } from "../../socket/socketConnection";

const FILTERS = [
  {
    key: 0,
    title: "@Me",
  }, // Display assigned to me, unread, starred, bot, unassigned & waiting in all category
  {
    key: 1,
    title: "Unread",
  },
  {
    key: 2,
    title: "Unassigned",
  },
  {
    key: 3,
    title: "Bot",
  },
];

export default function ChatList() {
  const dispatch = useDispatch();

  const [hovered, setHovered] = useState(null);

  const [openFilter, setOpenFilter] = useState(false);

  const {user_id} = useSelector((state) => state.auth);

  const { user } = useSelector((state) => state.user);

  const [searchTerm, setSearchTerm] = useState(""); // Controlled input state
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(""); // Debounced search term

  const { tagFilters } = useSelector((state) => state.app);

  const {
    filter,
    conversations,
    currentConversation,
    isLoading,
    totalUnread,
    totalUnassigned,
    pagination,
  } = useSelector((state) => state.chat);

  const currentPage = pagination?.currentPage || 1;
  const pageSize = pagination?.pageSize || 10;
  const totalConversations = pagination?.totalConversations || 10;
  const totalPages = pagination?.totalPages || 1;

  useEffect(() => {
    // Set up an interval to fetch conversations every 20 seconds
    const interval = setInterval(() => {
      dispatch(FetchCount());
    }, 60000); // 60 seconds = 60000 milliseconds

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    dispatch(FetchCount());
    // Dispatch GetConversations immediately on mount & when ever filter changes
    dispatch(GetConversations(tagFilters, filter, () => {}));
  }, [filter]);

  const handleToggleFilter = () => {
    setOpenFilter((p) => !p);
  };

  const [RenderList, setRenderList] = useState([]);

  useEffect(() => {
    // ** DONE: sort this list based on updatedAt so that chat with most recent updatedAt comes at top
    const LIST = conversations
      ?.map(
        ({
          _id,
          participant_name,
          participant_username,
          hasLastMessage,
          lastMsgContent,
          lastMsgType,
          lastMsgTimestamp,
          star,
          updatedAt,
          unread,
          answered,
          assignment,
          assignmentHistory,
          isAvailable,
          tags,
          unread_manual,
        }) => ({
          key: _id,
          name: participant_name,
          username: participant_username,
          hasLastMessage: hasLastMessage,
          lastMsg: lastMsgContent,
          lastMessageType: lastMsgType,
          lastMessageTimestamp: lastMsgTimestamp,
          updatedAt,
          star,
          unread,
          answered,
          assignment,
          assignmentHistory,
          isAvailable,
          tags: tags.map((e) => e.colorCode),
          unread_manual,
        })
      )
      .filter(
        (conversation) =>
          conversation.name
            ?.toLowerCase()
            .includes(debouncedSearchTerm?.toLowerCase()) ||
          conversation?.username
            ?.toLowerCase()
            ?.includes(debouncedSearchTerm?.toLowerCase())
      ) // Filter based on debouncedSearchTerm
      .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)); // Sort by updatedAt descending

    switch (filter) {
      case 0:
        // All
        setRenderList(
          LIST.filter(
            (e) =>
            {
              if(e.assignment) {
                if(e.assignment?.type === "Agent") {
                  if(e.assignment.agent.toString() === user_id.toString()) {
                    return true;
                  }
                  else {
                    return false;
                  }
                }
                else {
                  return false;
                }
              }
              else {
                return false;
              }
            }
          )
        ); // show all tickets except those who are assigned to some other employee than me
        break;
      case 1:
        // Unread
        setRenderList(
          LIST.filter((e) => {
            if(e.assignment) {
              if(e.assignment?.type === "Agent") {
                if(e.assignment.agent.toString() === user_id.toString()) {
                  return true;
                }
                else {
                  return false;
                }
              }
              else {
                return false;
              }
            }
            else {
              return true;
            }
          }).filter(
            (el) =>
              (el.unread !== 0 || el.unread_manual) &&
              !(el.assignment?.type === "Bot" && !el.isAvailable)
          )
        );
        break;
      case 2:
        // Unassigned
        setRenderList(LIST.filter((el) => !el.assignment && el.isAvailable));
        break;
      case 3:
        // Bot
        setRenderList(
          LIST.filter(
            (el) => el.assignment?.type?.toString() === "Bot"
          )
        );

      default:
        break;
    }
  }, [filter, conversations, debouncedSearchTerm]);

  const handleSelectConversation = (id) => {
    window.localStorage.setItem("current-conversation", id);
    dispatch(SetCurrentConversation(id));
    markConversationReadById(id);
  };

  // Debounce search term for 500ms after the last input change
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    return () => clearTimeout(handler); // Clear timeout if searchTerm changes
  }, [searchTerm]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value); // Update searchTerm without delay
  };

  return (
    <>
      <div className="hidden h-full flex-col xl:flex xl:w-1/4">
        {/* <!-- ====== Chat List Start --> */}
        <div className="sticky px-6 py-6">
          <div className="flex flex-row items-center justify-between">
            <h3 className="text-lg font-medium text-black dark:text-white 2xl:text-xl">
              Active Conversations
              <span className="rounded-md border-[.5px] border-stroke bg-gray-2 px-2 py-0.5 text-base font-medium text-black dark:border-strokedark dark:bg-boxdark-2 dark:text-white 2xl:ml-4">
                {RenderList.length || 0}
              </span>
            </h3>

            {/* Dropdown showing two options -> Manage Tags, Filter via Tags */}
            {/* Open a Modal for Managing Tags, another modal for setting tag filters */}
            <button onClick={handleToggleFilter}>
              <FunnelSimple size={24} />
            </button>
          </div>
        </div>

        <div className="flex max-h-full flex-col overflow-auto py-6 px-3 pt-0">
          <form className="sticky mb-3">
            <input
              type="text"
              value={searchTerm} // Controlled input
              onChange={handleSearchChange} // Handle input change
              className="w-full rounded-xl border border-stroke bg-gray-2 py-2.5 pl-5 pr-10 text-sm outline-none focus:border-primary dark:border-strokedark dark:bg-boxdark-2"
              placeholder="Search by name or username..."
            />
            <button className="absolute right-4 top-1/2 -translate-y-1/2">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.25 3C5.3505 3 3 5.3505 3 8.25C3 11.1495 5.3505 13.5 8.25 13.5C11.1495 13.5 13.5 11.1495 13.5 8.25C13.5 5.3505 11.1495 3 8.25 3ZM1.5 8.25C1.5 4.52208 4.52208 1.5 8.25 1.5C11.9779 1.5 15 4.52208 15 8.25C15 11.9779 11.9779 15 8.25 15C4.52208 15 1.5 11.9779 1.5 8.25Z"
                  fill="#637381"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11.957 11.958C12.2499 11.6651 12.7247 11.6651 13.0176 11.958L16.2801 15.2205C16.573 15.5133 16.573 15.9882 16.2801 16.2811C15.9872 16.574 15.5124 16.574 15.2195 16.2811L11.957 13.0186C11.6641 12.7257 11.6641 12.2508 11.957 11.958Z"
                  fill="#637381"
                />
              </svg>
            </button>
          </form>

          <div className="flex flex-row items-center justify-between mb-3">
            <div className="flex flex-row flex-wrap items-center gap-2">
              {FILTERS.map((el) => (
                <div
                  key={el.key}
                  onClick={() => {
                    dispatch(UpdateFilterCategory(el.key));
                  }}
                  className={`rounded-full p-1 px-3 hover:cursor-pointer hover:bg-opacity-90 ${
                    el.key === filter
                      ? "bg-primary/80 text-white"
                      : "text-body border bg-boxdark-2 border-stroke dark:border-strokedark"
                  }`}
                >
                  {el.title} {el.key === 1 && `(${totalUnread})`}{" "}
                  {el.key === 2 && `(${totalUnassigned})`}
                </div>
              ))}
            </div>
          </div>

          {tagFilters && tagFilters?.length > 0 && (
            <div
              onClick={() => {
                handleToggleFilter();
              }}
              className={`rounded-full p-1 px-3 mb-3 hover:cursor-pointer hover:bg-opacity-90 ${"text-primary"}`}
            >
              <div className="flex flex-row items-center justify-between">
                <span>Tag Filters ({tagFilters?.length})</span>
                <X size={24} />
              </div>
            </div>
          )}

          <div className="mb-3 border-b border-stroke dark:border-strokedark"></div>

          <div className="no-scrollbar max-h-full space-y-2.5 overflow-auto">
            {/* <!-- Chat List Item --> */}
            {RenderList.length > 0 ? (
              RenderList.map((object, item) => {
                return (
                  <div
                    key={item}
                    onClick={() => {
                      handleSelectConversation(object.key);
                    }}
                    onMouseEnter={() => setHovered(item)}
                    onMouseLeave={() => setHovered(null)}
                    className={`flex flex-row cursor-pointer items-center justify-between rounded px-4 py-2 dark:hover:bg-strokedark ${
                      currentConversation === object.key
                        ? "bg-gray dark:bg-boxdark-2"
                        : "dark:bg-boxdark"
                    }`}
                  >
                    <div className="flex flex-row items-center">
                      <div className="relative mr-3.5 h-11 w-full max-w-11 rounded-full">
                        <div className="h-11 w-11 rounded-full border border-stroke dark:border-strokedark bg-boxdark-2 flex items-center justify-center uppercase">
                          {object?.assignment?.type === "Bot" ? (
                            <Robot size={24} className="text-yellow-500" />
                          ) : object?.assignment?.type === "Agent" ? (
                            <UserCircleCheck
                              size={24}
                              className="text-primary"
                            />
                          ) : (
                            <Empty size={24} className="text-green-500" />
                          )}
                        </div>
                        {/* // ONLINE STATUS */}
                        {/* <span className="absolute bottom-0 right-0 block h-3 w-3 rounded-full border-2 border-gray-2 bg-success"></span> */}
                      </div>
                      <div className="w-full">
                        <div className="flex flex-row items-center space-x-1">
                          <h5 className="text-sm font-medium text-black dark:text-white">
                            {object.name}
                          </h5>

                          {object.tags?.map((el) => (
                            <TagChevron key={el} color={el} weight="fill" />
                          ))}
                          {/* <span className="text-primary">Bot</span> */}
                        </div>
                        {object.hasLastMessage && (
                          <div className="flex flex-row items-center space-x-2">
                            {(() => {
                              switch (object.lastMessageType) {
                                case "Text":
                                  break;

                                case "Media":
                                  return <Image />;

                                case "Audio":
                                  return <Microphone />;

                                case "Document":
                                  return <File />;
                                case "Giphy":
                                  return <Gif />;

                                default:
                                  break;
                              }
                            })()}
                            <p className="text-sm">
                              {truncateString(object?.lastMsg, 25)}
                            </p>
                          </div>
                        )}

                        {/* show icons based on ticket assignment - bot, assigned to Me, unassigned */}
                      </div>
                    </div>
                    <div>
                      <div className="flex flex-col space-y-1.5 items-end">
                        {object.hasLastMessage && (
                          <div className="text-xs">{`${new Date(
                            object.lastMessageTimestamp
                          ).getHours()}:${new Date(
                            object.lastMessageTimestamp
                          ).getMinutes()}`}</div>
                        )}

                        {/* // UNREAD COUNT */}
                        <div className="flex flex-row items-center space-x-1">
                          {!object.answered && (
                            <HandPalm
                              weight="fill"
                              size={20}
                              className="text-primary"
                            />
                          )}
                          {(object.unread !== 0 || object.unread_manual) && (
                            <div className="p-1 h-5 w-5 bg-primary text-white font-semibold rounded-full flex flex-row items-center justify-center text-xs">
                              {object.unread || ""}
                            </div>
                          )}
                          {hovered === item && <ChatPopover />}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : isLoading ? (
              <></>
            ) : (
              <div className="flex flex-col space-y-4 items-center justify-center text-lg text-center min-h-100">
                <Empty size={40} />
                <span>No Conversation Found</span>
              </div>
            )}
            {isLoading ? (
              <div className="flex flex-row space-x-2 items-center justify-center text-lg text-center">
                <svg
                  className="mr-2 inline h-4 w-4 animate-spin text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C3.58 0 0 3.58 0 8h4z"
                  ></path>
                </svg>
                <span>Loading...</span>
              </div>
            ) : totalPages * 1 > currentPage * 1 && !isLoading ? (
              <div className="flex flex-row items-center justify-center">
                <button
                  disabled={isLoading}
                  className="text-primary text-center"
                  onClick={() => {
                    dispatch(
                      GetConversations(
                        tagFilters,
                        filter,
                        () => {},
                        currentPage * 1 + 1
                      )
                    );
                  }}
                >
                  Load more chats
                </button>
              </div>
            ) : (
              !isLoading && (
                <div className="flex flex-row items-center justify-center">
                  <div className="text-primary text-center">
                    You've seen all conversations!
                  </div>
                </div>
              )
            )}
            {/* <!-- Chat List Item --> */}
          </div>
        </div>
        {/* <!-- ====== Chat List End --> */}
      </div>

      {openFilter && (
        <TagFilter open={openFilter} handleClose={handleToggleFilter} />
      )}
    </>
  );
}
