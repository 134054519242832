import { DownloadSimple, File } from "@phosphor-icons/react";
import React, { useState } from "react";
import MessagePopover from "../Popover/MessagePopover";
import truncateString from "../../utils/truncate";
import Deleted from "./Deleted";

export default function Document({
  disableAction,
  id,
  incoming,
  author,
  timestamp,
  content,
  documentFile,
  edited,
  active,
  sender,
  agent_name,
}) {
  const [hovered, setHovered] = useState(false);

  const handleDownloadClick = () => {
    const link = document.createElement("a");
    link.href = documentFile.url;
    link.target = "_blank";
    link.download = documentFile.name || "documentFile";
    link.click();
  };

  return active ? (
    incoming ? (
      <div className={`max-w-125 w-fit`}>
        <p className="mb-2.5 text-sm font-medium capitalize">{author}</p>
        <div
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(null)}
          className="relative mb-2.5 rounded-2xl rounded-tl-none bg-gray px-5 py-3 dark:bg-boxdark-2 space-y-2"
        >
          <p className="mb-2.5 text-sm font-medium">{sender?.type}</p>
          <div className="flex flex-row items-center justify-between p-2 bg-gray-3 rounded-md dark:bg-boxdark">
            <div className="flex flex-row items-center space-x-3">
              <div className="p-2 rounded-md bg-primary/80 text-white">
                <File size={20} />
              </div>
              <div className="flex flex-col">
                <div>{truncateString(documentFile?.name)}</div>
                <div className="text-sm font-medium">
                  {documentFile?.size}MB
                </div>
              </div>
            </div>
            <button onClick={handleDownloadClick} className="pl-5">
              <DownloadSimple size={20} />
            </button>
          </div>

          <p>{content}</p>

          {!disableAction && hovered && (
            <div className="absolute top-2 right-2">
              {/* <MessagePopover id={id} incoming={incoming} /> */}
            </div>
          )}
        </div>
        <div className="flex flex-row items-center justify-end space-x-2">
          {edited && <p className="text-xs">Edited</p>}
          <p className="text-xs">{timestamp}</p>
        </div>
      </div>
    ) : (
      <div className="ml-auto max-w-125 w-fit">
        <div
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(null)}
          className="relative mb-2.5 rounded-2xl rounded-br-none bg-primary px-5 py-3 space-y-2 text-white"
        >
          <p className="mb-2.5 text-sm font-medium">
            {sender?.type} ({agent_name})
          </p>
          <div className="flex flex-row items-center justify-between bg-white p-2 rounded-md text-primary">
            <div className="flex flex-row items-center space-x-3">
              <div className="p-2 rounded-md bg-primary/20 text-primary">
                <File size={20} />
              </div>
              <div className="flex flex-col">
                <div>{truncateString(documentFile?.name)}</div>
                <div className="text-sm font-medium">
                  {documentFile?.size}MB
                </div>
              </div>
            </div>
            <button onClick={handleDownloadClick} className="pl-5">
              <DownloadSimple size={20} />
            </button>
          </div>
          <p>{content}</p>

          {!disableAction && hovered && (
            <div className="absolute top-2 right-2">
              {/* <MessagePopover id={id} incoming={incoming} /> */}
            </div>
          )}
        </div>
        <div className="flex flex-row items-center justify-end space-x-2">
          {edited && <p className="text-xs">Edited</p>}
          <p className="text-right text-xs">{timestamp}</p>
        </div>
      </div>
    )
  ) : (
    <Deleted incoming={incoming} id={id} />
  );
}
