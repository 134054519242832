import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { toast } from "react-toastify";

const initialState = {
  isLoading: false,
  error: null,
  devices: [],
};

const slice = createSlice({
  name: "device",
  initialState,
  reducers: {
    setError(state, action) {
      state.error = action.payload;
    },
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    fetchDevicesSuccess(state, action) {
      state.devices = action.payload;
    },
    updateDeviceSuccess(state, action) {
      state.devices = state.devices.map((device) => {
        if (device._id === action.payload._id) {
          return action.payload;
        } else {
          return device;
        }
      });
    },
    deleteDeviceSuccess(state, action) {
      state.devices = state.devices.filter((device) => device._id !== action.payload.id);
    },
    addDeviceSuccess(state, action) {
      state.devices.unshift(action.payload);
    },
  },
});

export default slice.reducer;

const {
  setError,
  setLoading,
  addDeviceSuccess,
  deleteDeviceSuccess,
  fetchDevicesSuccess,
  updateDeviceSuccess,
} = slice.actions;

// CreateDevice
export function CreateDevice(deviceData, handleClose) {
  return async (dispatch, getState) => {
    dispatch(setError(null));
    dispatch(setLoading(true));
    try {
      const response = await axios.post("/employee/device", deviceData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      });
      console.log(response);
      dispatch(addDeviceSuccess(response?.data?.data?.device));
      toast.success("Device created successfully!");
    } catch (error) {
      dispatch(setError(error));
      toast.error(error?.message || "Failed to create device.");
    } finally {
      dispatch(setLoading(false));
      handleClose();
    }
  };
}

// Get Devices
export function FetchDevices(search = "") {
  return async (dispatch, getState) => {
    dispatch(setError(null));
    dispatch(setLoading(true));
    try {
      const response = await axios.get("/employee/devices", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
        params: {
          search, // Include search query as a parameter
        },
      });

      console.log(response);
      dispatch(fetchDevicesSuccess(response?.data?.data?.devices));
    } catch (error) {
      dispatch(setError(error));
      toast.error(error?.message || "Failed to fetch devices.");
    } finally {
      dispatch(setLoading(false));
    }
  };
}

// Update Device
export function UpdateDevice(deviceData, handleClose) {
  return async (dispatch, getState) => {
    dispatch(setError(null));
    dispatch(setLoading(true));
    try {
      const response = await axios.patch(`/employee/device`, deviceData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      });
      console.log(response);
      dispatch(updateDeviceSuccess(response?.data?.data?.device));
      toast.success("Device updated successfully!");
    } catch (error) {
      dispatch(setError(error));
      toast.error(error?.message || "Failed to update device.");
    } finally {
      dispatch(setLoading(false));
      handleClose();
    }
  };
}

// Delete Device
export function DeleteDeviceById(deviceId, handleClose) {
  return async (dispatch, getState) => {
    dispatch(setError(null));
    dispatch(setLoading(true));
    try {
      const response = await axios.delete(`/employee/device/${deviceId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      });
      console.log(response);
      dispatch(deleteDeviceSuccess({ id: deviceId }));
      toast.success("Device deleted successfully!");
    } catch (error) {
      dispatch(setError(error.message || "Failed to delete device."));
      toast.error(error?.message || "Failed to delete device.");
    } finally {
      dispatch(setLoading(false));
      handleClose();
    }
  };
}
