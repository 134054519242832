import React, { useState } from "react";
import extractLinks from "../../utils/extractLinks";
import Microlink from "@microlink/react";
// import MessagePopover from "../Popover/MessagePopover";
import Deleted from "./Deleted";

export default function Text({
  id,
  incoming,
  author,
  timestamp,
  content,
  disableAction,
  edited,
  active,
  sender,
  agent_name,
}) {
  const { links, originalString } = extractLinks(content, incoming);

  const [hovered, setHovered] = useState(false);

  return active ? (
    <div className="relative">
      {incoming ? (
        <div
          className={`max-w-125 w-fit`}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(null)}
        >
          <p className="mb-2.5 text-sm font-medium">{sender?.type}</p>
          <p className="mb-2.5 text-sm font-medium">{author}</p>
          <div className="relative mb-2.5 rounded-2xl rounded-tl-none bg-gray text-white px-5 py-3 dark:bg-boxdark space-y-2">
            <p dangerouslySetInnerHTML={{ __html: originalString }}></p>
            {links.length > 0 && (
              <Microlink style={{ width: "100%" }} url={links[0]} />
            )}
            {!disableAction && hovered && (
              <div className="absolute top-2 right-2">
                {/* <MessagePopover id={id} incoming={incoming} /> */}
              </div>
            )}
          </div>
          <div className="flex flex-row items-center justify-end space-x-2">
            {edited && <p className="text-xs">Edited</p>}
            <p className="text-xs">{timestamp}</p>
          </div>
        </div>
      ) : (
        <div
          className="ml-auto max-w-125 w-fit"
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(null)}
        >
          <p className="mb-2.5 text-sm font-medium">{sender?.type} ({agent_name})</p>
          <div className="relative mb-2.5 rounded-2xl rounded-br-none bg-primary px-5 py-3 space-y-2">
            <p
              className="text-white"
              dangerouslySetInnerHTML={{ __html: originalString }}
            ></p>
            {links.length > 0 && (
              <Microlink style={{ width: "100%" }} url={links[0]} />
            )}
            {!disableAction && hovered && (
              <div className="absolute top-2 right-2">
                {/* <MessagePopover id={id} incoming={incoming} /> */}
              </div>
            )}
          </div>
          <div className="flex flex-row items-center justify-end space-x-2">
            {edited && <p className="text-xs">Edited</p>}
            <p className="text-xs">{timestamp}</p>
          </div>
        </div>
      )}
    </div>
  ) : (
    <Deleted id={id} incoming={incoming} />
  );
}
