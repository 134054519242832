import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { toast } from "react-toastify";

const initialState = {
  isLoading: false,
  error: null,
  servers: [],
};

const slice = createSlice({
  name: "server",
  initialState,
  reducers: {
    setError(state, action) {
      state.error = action.payload;
    },
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    fetchServersSuccess(state, action) {
      state.servers = action.payload;
    },
    updateServerSuccess(state, action) {
      state.servers = state.servers.map((server) => {
        if (server._id === action.payload._id) {
          return action.payload;
        } else {
          return server;
        }
      });
    },
    deleteServerSuccess(state, action) {
      state.servers = state.servers.filter((server) => server._id !== action.payload.id);
    },
    addServerSuccess(state, action) {
      state.servers.unshift(action.payload);
    },
  },
});

export default slice.reducer;

const {
  setError,
  setLoading,
  addServerSuccess,
  deleteServerSuccess,
  fetchServersSuccess,
  updateServerSuccess,
} = slice.actions;

// CreateServer
export function CreateServer(serverData, handleClose) {
  return async (dispatch, getState) => {
    dispatch(setError(null));
    dispatch(setLoading(true));
    try {
      const response = await axios.post("/employee/server", serverData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      });
      console.log(response);
      dispatch(addServerSuccess(response?.data?.data?.iptvServer));
      toast.success("Server created successfully!");
    } catch (error) {
      dispatch(setError(error));
      toast.error(error?.message || "Failed to create server.");
    } finally {
      dispatch(setLoading(false));
      handleClose();
    }
  };
}

// Get Servers
export function FetchServers(search = "") {
  return async (dispatch, getState) => {
    dispatch(setError(null));
    dispatch(setLoading(true));
    try {
      const response = await axios.get("/employee/servers", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
        params: {
          search, // Include search query as a parameter
        },
      });

      console.log(response);
      dispatch(fetchServersSuccess(response?.data?.data?.servers));
    } catch (error) {
      dispatch(setError(error));
      toast.error(error?.message || "Failed to fetch servers.");
    } finally {
      dispatch(setLoading(false));
    }
  };
}

// Update Server
export function UpdateServer(serverData, handleClose) {
  return async (dispatch, getState) => {
    dispatch(setError(null));
    dispatch(setLoading(true));
    try {
      const response = await axios.patch(`/employee/server`, serverData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      });
      console.log(response);
      dispatch(updateServerSuccess(response?.data?.data?.server));
      toast.success("Server updated successfully!");
    } catch (error) {
      dispatch(setError(error));
      toast.error(error?.message || "Failed to update server.");
    } finally {
      dispatch(setLoading(false));
      handleClose();
    }
  };
}

// Delete Server
export function DeleteServerById(serverId, handleClose) {
  return async (dispatch, getState) => {
    dispatch(setError(null));
    dispatch(setLoading(true));
    try {
      const response = await axios.delete(`/employee/server/${serverId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      });
      console.log(response);
      dispatch(deleteServerSuccess({ id: serverId }));
      toast.success("Server deleted successfully!");
    } catch (error) {
      dispatch(setError(error.message || "Failed to delete server."));
      toast.error(error?.message || "Failed to delete server.");
    } finally {
      dispatch(setLoading(false));
      handleClose();
    }
  };
}
