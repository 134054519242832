import { X } from "@phosphor-icons/react";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { CreateApp } from "../../redux/slices/application";

export default function AddApplication({ open, handleClose }) {
  const modal = useRef(null);
  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state.application);

  // Yup validation schema
  const schema = yup.object().shape({
    name: yup
      .string()
      .required("Application name is required")
      .min(2, "App name must be atleast 2 characters")
      .max(30, "Max 30 characters"),
  });

  // Initialize react-hook-form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    console.log("Submitted Data:", data);
    // Dispatch your action or perform other submit logic here
    dispatch(CreateApp({ ...data }, handleClose));
  };

  // Close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!open || keyCode !== 27) return;
      handleClose();
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  }, [open, handleClose]);

  return (
    <div
      className={`fixed left-0 top-0 z-999999 flex h-full min-h-screen w-full items-center justify-center bg-black/90 px-4 py-5 ${
        open ? "block" : "hidden"
      }`}
    >
      <div
        ref={modal}
        className="w-full max-w-142.5 space-y-10 rounded-lg bg-white px-8 py-12 text-center dark:bg-boxdark md:px-17.5 md:py-8"
      >
        <div className="flex flex-row items-center justify-between">
          <div className="font-semibold text-black dark:text-white">
            Add New App
          </div>
          <button onClick={handleClose}>
            <X size={20} />
          </button>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <div className="relative">
              <input
                type="text"
                placeholder="Enter app name"
                className="w-full rounded-lg border border-stroke bg-transparent py-4 pl-6 pr-10 text-black outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                {...register("name")}
              />
              {errors.name && (
                <p className="mt-2 text-start text-sm text-red">
                  {errors.name.message}
                </p>
              )}
            </div>
          </div>

          <div className="mb-5">
            <button
              type="submit"
              disabled={isLoading}
              className="w-full cursor-pointer rounded-lg border border-primary bg-primary p-4 text-white transition hover:bg-opacity-90"
            >
              {isLoading ? (
                <>
                  <svg
                    className="mr-2 inline h-4 w-4 animate-spin text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C3.58 0 0 3.58 0 8h4z"
                    ></path>
                  </svg>
                  Submitting...
                </>
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
