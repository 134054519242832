import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { toast } from "react-toastify";

const initialState = {
  isLoading: false,
  error: null,
  apps: [],
};

const slice = createSlice({
  name: "application",
  initialState,
  reducers: {
    setError(state, action) {
      state.error = action.payload;
    },
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    fetchAppsSuccess(state, action) {
      state.apps = action.payload;
    },
    updateAppSuccess(state, action) {
      state.apps = state.apps.map((app) => {
        if (app._id === action.payload._id) {
          return action.payload;
        } else {
          return app;
        }
      });
    },
    deleteAppSuccess(state, action) {
      state.apps = state.apps.filter((app) => app._id !== action.payload.id);
    },
    addAppSuccess(state, action) {
      state.apps.unshift(action.payload);
    },
  },
});

export default slice.reducer;

const {
  setError,
  setLoading,
  addAppSuccess,
  deleteAppSuccess,
  fetchAppsSuccess,
  updateAppSuccess,
} = slice.actions;

// CreateApp
export function CreateApp(appData, handleClose) {
  return async (dispatch, getState) => {
    dispatch(setError(null));
    dispatch(setLoading(true));
    try {
      const response = await axios.post("/employee/app", appData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      });
      console.log(response);
      dispatch(addAppSuccess(response?.data?.data?.application));
      toast.success("App created successfully!");
    } catch (error) {
      dispatch(setError(error));
      toast.error(error?.message || "Failed to create app.");
    } finally {
      dispatch(setLoading(false));
      handleClose();
    }
  };
}

// Get Apps
export function FetchApps(search = "") {
  return async (dispatch, getState) => {
    dispatch(setError(null));
    dispatch(setLoading(true));
    try {
      const response = await axios.get("/employee/apps", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
        params: {
          search, // Include search query as a parameter
        },
      });

      console.log(response);
      dispatch(fetchAppsSuccess(response?.data?.data?.applications));
    } catch (error) {
      dispatch(setError(error));
      toast.error(error?.message || "Failed to fetch apps.");
    } finally {
      dispatch(setLoading(false));
    }
  };
}

// Update App
export function UpdateApp(appData, handleClose) {
  return async (dispatch, getState) => {
    dispatch(setError(null));
    dispatch(setLoading(true));
    try {
      const response = await axios.patch(`/employee/app`, appData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      });
      console.log(response);
      dispatch(updateAppSuccess(response?.data?.data?.application));
      toast.success("App updated successfully!");
    } catch (error) {
      dispatch(setError(error));
      toast.error(error?.message || "Failed to update app.");
    } finally {
      dispatch(setLoading(false));
      handleClose();
    }
  };
}

// Delete App
export function DeleteAppById(appId, handleClose) {
  return async (dispatch, getState) => {
    dispatch(setError(null));
    dispatch(setLoading(true));
    try {
      const response = await axios.delete(`/employee/app/${appId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth.token}`,
        },
      });
      console.log(response);
      dispatch(deleteAppSuccess({ id: appId }));
      toast.success("App deleted successfully!");
    } catch (error) {
      dispatch(setError(error.message || "Failed to delete app."));
      toast.error(error?.message || "Failed to delete app.");
    } finally {
      dispatch(setLoading(false));
      handleClose();
    }
  };
}
