import { CaretDown, Globe } from "@phosphor-icons/react";
import React from "react";
import { useSelector } from "react-redux";

export default function SelectApplication({ register }) {
  const { apps } = useSelector((state) => state.application);
  return (
    <div>
      <label className="mb-3 block text-black text-start dark:text-white">
        Select Application
      </label>

      <div className="relative z-20 bg-white dark:bg-form-input">
        <select
          {...register("application")}
          className={`relative z-20 w-full appearance-none rounded border border-stroke bg-transparent px-12 py-3 outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input`}
        >
          <option value="" disabled className="text-body dark:text-bodydark">
            Select Application
          </option>
          {apps?.map(({ _id, name }) => (
            <option
              key={_id}
              value={_id}
              className="text-body dark:text-bodydark"
            >
              {name}
            </option>
          ))}
        </select>

        <span className="absolute top-1/2 right-4 z-10 -translate-y-1/2">
          <CaretDown size={20} />
        </span>
      </div>
    </div>
  );
}
