import React, { useState } from "react";
import MessagePopover from "../Popover/MessagePopover";
import Deleted from "./Deleted";

export default function Giphy({
  id,
  disableAction,
  incoming,
  author,
  timestamp,
  content,
  giphy,
  edited,
  active,
  sender,
  agent_name,
}) {
  const [hovered, setHovered] = useState(false);
  return active ? (
    incoming ? (
      <div className="max-w-100 w-fit">
        <p className="mb-2.5 text-sm font-medium">{author}</p>
        <div
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(null)}
          className="relative mb-2.5 rounded-2xl rounded-tl-none bg-gray px-5 py-3 dark:bg-boxdark-2 space-y-2 text-white"
        >
          <p className="mb-2.5 text-sm font-medium">{sender?.type}</p>
          <img src={giphy} className="w-full mt-3" />
          <p>{content}</p>
          {!disableAction && hovered && (
            <div className="absolute top-2 right-2">
              {/* <MessagePopover id={id} incoming={incoming} /> */}
            </div>
          )}
        </div>
        <div className="flex flex-row items-center justify-end space-x-2">
          {edited && <p className="text-xs">Edited</p>}
          <p className="text-xs">{timestamp}</p>
        </div>
      </div>
    ) : (
      <div className="max-w-100 ml-auto w-fit">
        <div
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(null)}
          className="relative mb-2.5 rounded-2xl rounded-br-none bg-primary px-5 py-3 space-y-2"
        >
          <p className="mb-2.5 text-sm font-medium">{sender?.type} ({agent_name})</p>
          <img src={giphy} className="w-full mt-3" />
          <p className="text-white">{content}</p>

          {!disableAction && hovered && (
            <div className="absolute top-2 right-2">
              {/* <MessagePopover id={id} incoming={incoming} /> */}
            </div>
          )}
        </div>

        <div className="flex flex-row items-center justify-end space-x-2">
          {edited && <p className="text-xs">Edited</p>}
          <p className="text-xs">{timestamp}</p>
        </div>
      </div>
    )
  ) : (
    <Deleted incoming={incoming} id={id} />
  );
}
