import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
// slices
import applicationReducer from "./slices/application";
import deviceReducer from "./slices/device";
import serverReducer from "./slices/server";
import appReducer from "./slices/app";
import authReducer from "./slices/auth";
import tagReducer from "./slices/tag";
import responseReducer from "./slices/response";
import userReducer from "./slices/user";
import chatReducer from "./slices/chat";

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
};

const rootReducer = combineReducers({
  application: applicationReducer,
  device: deviceReducer,
  app: appReducer,
  auth: authReducer,
  tag: tagReducer,
  response: responseReducer,
  user: userReducer,
  chat: chatReducer,
  server: serverReducer,
});

export { rootPersistConfig, rootReducer };
