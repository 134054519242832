import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SetBlob,
  ToggleCannedMediaModal,
  ToggleMediaModal,
} from "../../redux/slices/app";
import AttachmentPopover from "../../components/AttachmentPopover";
import EmojiPicker from "../../components/EmojiPicker";
import Giphy from "../../components/Giphy";
import { sendDirectMessage } from "../../socket/socketConnection";

export default function Input() {
  const dispatch = useDispatch();

  const { responses } = useSelector((state) => state.response);

  const { user } = useSelector((state) => state.user);
  const { currentParticipant, currentConversation, conversations } =
    useSelector((state) => state.chat);

  const this_conversation = conversations.find(
    (el) => el._id.toString() === currentConversation?.toString()
  );

  const isTicketAvailable = this_conversation?.isAvailable
    ? true
    : this_conversation?.assignment?.type === "Agent"
    ? this_conversation?.assignment?.agent.toString() === user._id
    : false; // Available means its either assigned to me or is not taken by someone else

  const responseList = responses.map((el) => ({
    key: el._id,
    title: el.title,
    response: el.content,
    image: el.image,
    type: el.image ? "Image" : "Text",
  }));

  const [isTyping, setIsTyping] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [showGifPicker, setShowGifPicker] = useState(false);
  const [showCannedModal, setShowCannedModal] = useState(false);
  const [filteredResponses, setFilteredResponses] = useState([]);

  const textareaRef = useRef(null);

  const handleInputChange = (e) => {
    if (!isTyping) {
      setIsTyping(true);
    }
    setInputValue(e.target.value);
  };

  useEffect(() => {
    if (inputValue.startsWith("/")) {
      const query = inputValue.slice(1)?.toLowerCase();
      const filtered = responseList.filter((response) =>
        response?.title?.toLowerCase().includes(query)
      );
      setFilteredResponses(filtered);
      setShowCannedModal(true);
    } else {
      setShowCannedModal(false);
    }
  }, [inputValue]);

  // Handle selecting a response
  const selectResponse = (response) => {
    // if type = text
    if (response?.type === "Text") {
      setInputValue(response.response);
    } else {
      // Open Media Modal with prefilled image & Text
      dispatch(
        ToggleCannedMediaModal({
          open: true,
          content: response.response,
          url: response.image,
        })
      );
    }

    setShowCannedModal(false);
  };

  // Handle Send Msg
  const handleSendMsg = () => {
    if (inputValue.trim()) {
      const data = {
        participant: currentParticipant,
        message: {
          author: user._id,
          type: "Text",
          content: inputValue,
        },
      };

      sendDirectMessage(data);

      setInputValue("");
      textareaRef.current.style.height = "auto"; // Reset height after sending the message
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (e.shiftKey) {
        // Add a new line if Shift + Enter is pressed
        setInputValue((prev) => prev + "\n");
      } else {
        // Send the message if only Enter is pressed
        e.preventDefault();
        handleSendMsg();
      }
    }
  };

  const handleEmojiSelect = (emoji) => {
    setInputValue((prev) => prev + emoji.native); // Append selected emoji to input value
  };

  const autoResizeTextarea = () => {
    const textarea = textareaRef.current;

    if (textarea) {
      textarea.style.height = "auto"; // Reset height to auto to calculate the new height
      textarea.style.height = `${textarea.scrollHeight}px`; // Set height based on scrollHeight

      // Add padding for a single line to make it look larger
      if (textarea.scrollHeight <= 24) {
        textarea.style.paddingTop = "8px";
        textarea.style.paddingBottom = "8px";
      } else {
        textarea.style.paddingTop = "0";
        textarea.style.paddingBottom = "0";
      }
    }
  };

  useEffect(() => {
    autoResizeTextarea(); // Adjust height whenever inputValue changes
  }, [inputValue]);

  const handlePaste = (event) => {
    const items = event.clipboardData.items;
    let handled = false;

    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      // Check for images
      if (item.type.startsWith("image/")) {
        const blob = item.getAsFile();
        const url = URL.createObjectURL(blob);
        dispatch(SetBlob(blob));
        dispatch(ToggleMediaModal(true));
        // setImageSrc(url);
        console.log("Image pasted:", blob);
        handled = true;
        break; // Stop processing further items once an image is found
      }

      // Check for text
      if (item.type === "text/plain") {
        const textData = event.clipboardData.getData("text/plain");
        // setInputValue(textData);
        console.log("Text pasted:", textData);
        handled = true;
        break; // Stop processing further items once text is found
      }
    }

    if (!handled) {
      console.log("No valid image or text data found in clipboard.");
    }
  };

  return (
    <div className="sticky bottom-0 border-t border-stroke bg-white px-3 py-3 dark:border-strokedark dark:bg-boxdark flex flex-col space-y-4">
      {isTicketAvailable ? (
        // TICKET AVAILABLE: Can reply
        <form className="flex items-start justify-between space-x-4.5">
          <div className="relative w-full h-min max-h-[52px]">
            <textarea
              ref={textareaRef}
              placeholder="mensagem"
              className="h-auto min-h-12 placeholder:py-3.5 w-full max-h-36 overflow-hidden rounded-xl border border-stroke bg-gray pl-5 pr-19 text-black placeholder-body outline-none focus:border-primary dark:border-strokedark dark:bg-boxdark-2 dark:text-white resize-none"
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              rows={1}
              style={{
                // padding: "12px 16px", // Consistent padding
                lineHeight: 1.5, // Adjust line height to center text
              }}
              onPaste={handlePaste}
            />
            <div className="absolute right-5 top-1/2 inline-flex -translate-y-1/2 items-center justify-end space-x-4">
              {/* <button
              onClick={(e) => {
                e.preventDefault();
                dispatch(ToggleAudioModal(true));
              }}
              className="hover:text-primary"
            >
              <Microphone size={20} />
            </button> */}
              <button className="hover:text-primary">
                <AttachmentPopover />
              </button>
              {/* <button
              className="hover:text-primary"
              onClick={(e) => {
                e.preventDefault();
                setShowGifPicker((prev) => !prev);
              }}
            >
              <Gif size={20} />
            </button> */}
              <button
                onClick={(e) => {
                  e.preventDefault();
                }}
                className="hover:text-primary"
              >
                <EmojiPicker onSelectEmoji={handleEmojiSelect} />
              </button>
            </div>
          </div>
          <button
            onClick={handleSendMsg}
            disabled={!inputValue.trim()}
            className={`flex items-center justify-center h-[52px] max-w-13 w-full rounded-md  hover:bg-opacity-90 ${
              !inputValue.trim()
                ? "bg-gray text-body dark:bg-boxdark-2 dark:text-body"
                : "bg-primary text-white"
            }`}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22 2L11 13"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M22 2L15 22L11 13L2 9L22 2Z"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </form>
      ) : (
        // TICKET ASSIGNED TO SOMEONE ELSE: Can't reply
        <div className="text-center text-md">
          This ticket is assigned to someone else
        </div>
      )}

      {showGifPicker && <Giphy />}

      {/* Canned Responses Modal */}
      {showCannedModal && (
        <div className="absolute bottom-16 left-0 right-0 bg-white border border-stroke rounded shadow-lg p-2 z-10 dark:bg-boxdark dark:border-strokedark h-100 no-scrollbar overflow-y-scroll">
          {filteredResponses.length > 0 ? (
            filteredResponses.map((response, index) => (
              <button
                key={index}
                className="w-full text-left p-2 hover:bg-gray-100 dark:hover:bg-gray-800"
                onClick={() => selectResponse(response)}
              >
                <strong>
                  {response.title} ({response?.type})
                </strong>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  {response.response}
                </p>
              </button>
            ))
          ) : (
            <p className="p-2 text-gray-500 dark:text-gray-400">
              No matching responses
            </p>
          )}
        </div>
      )}
    </div>
  );
}
