import React, { useState } from "react";
import Waveform from "../Waveform";
import MessagePopover from "../Popover/MessagePopover";
import Deleted from "./Deleted";

export default function Voice({
  disableAction,
  id,
  incoming,
  timestamp,
  audioUrl,
  active,
  sender,
  agent_name,
}) {
  const [hovered, setHovered] = useState(false);
  return active ? (
    incoming ? (
      <div className="max-w-125">
        <div
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(null)}
          className="relative mb-2.5 rounded-2xl rounded-tl-none px-5 py-3"
        >
          <p className="mb-2.5 text-sm font-medium">{sender?.type}</p>
          <Waveform incoming={incoming} audioUrl={audioUrl} />
          {!disableAction && hovered && (
            <div className="absolute top-2 right-2">
              {/* <MessagePopover id={id} incoming={incoming} isVoice /> */}
            </div>
          )}
        </div>
        <p className="text-right text-xs">{timestamp}</p>
      </div>
    ) : (
      <div className="ml-auto max-w-125">
        <div
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(null)}
          className="relative mb-2.5 rounded-2xl rounded-br-none px-5 py-3"
        >
          <p className="mb-2.5 text-sm font-medium">{sender?.type} ({agent_name})</p>
          <Waveform incoming={incoming} audioUrl={audioUrl} />
          {!disableAction && hovered && (
            <div className="absolute top-2 right-2">
              {/* <MessagePopover id={id} incoming={incoming} isVoice /> */}
            </div>
          )}
        </div>
        <div className="flex flex-row items-center justify-end space-x-2">
          <p className="text-right text-xs">{timestamp}</p>
        </div>
      </div>
    )
  ) : (
    <Deleted incoming={incoming} id={id} />
  );
}
